<!-- Start Footer Area -->
<footer class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo2.png" alt="logo">
                        </a>
                    </div>
                    <p>Lorem ipsum dolor consectetur adipiscing elit, eiusmod tempor ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <!-- <div class="item">
                <div class="single-footer-widget">
                    <h3>New York</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>198 Collective Street <br> Manhattan <br> Kingston <br> United State</p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>London</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>352/71 Second Street <br> King Street <br> Kingston <br> United Kingdom</p>
                    </div>
                </div>
            </div> -->

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li><a href="mailto:info@intranube.com">info@intranube.com</a></li>
                        <li><a href="#">fax@intranube.com</a></li>
                        <li><a href="tel:+444785417452">+44 478 541 7452</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-logo-1"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <p>© Intranube. All Rights Reserved, 2023</p>
                </div>

                <div class="item">
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <!-- <div class="br-line"></div> -->
    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div>
</footer>
<!-- End Footer Area -->
