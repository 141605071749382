<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>About</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>About</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Leading the way in Creative Digital Agency</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Best Digital Agency in the World</h3>
                        <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                        <p>We are an experienced and talented team of passionate.</p>
                        <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing.</p>

                        <div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-section uk-feedback">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="feedback-img">
                    <img src="assets/img/women.jpg" alt="image">

                    <img src="assets/img/1.png" class="shape-img" alt="image">

                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-multimedia"></i> Watch Video</a>
                </div>
            </div>

            <div class="item">
                <div class="feedback-inner">
                    <div class="uk-section-title section-title">
                        <span>What Client Says About US</span>
                        <h2>Our Testimonials</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client">
                                <h3>Jason Statham</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Team Area -->
<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Our Team</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">View All</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.jpg" alt="image">

            <div class="team-content">
                <h3>David Warner</h3>
                <span>UX/UI Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.jpg" alt="image">

            <div class="team-content">
                <h3>Emili Lucy</h3>
                <span>Project Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.jpg" alt="image">

            <div class="team-content">
                <h3>Steven Smith</h3>
                <span>Marketing Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.jpg" alt="image">

            <div class="team-content">
                <h3>Steve Eva</h3>
                <span>Creative Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>Subscribe to our newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="name@gmail.com" name="EMAIL" required autocomplete="off">

                    <button type="submit" class="uk-button uk-button-default">Subscribe Now</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section>
<!-- End Subscribe Area -->
