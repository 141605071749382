<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Team</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Team</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/team1.jpg" alt="image">

                <div class="team-content">
                    <h3>Josh Buttler</h3>
                    <span>Content Writer</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/team2.jpg" alt="image">

                <div class="team-content">
                    <h3>David Warner</h3>
                    <span>UX/UI Designer</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/team3.jpg" alt="image">

                <div class="team-content">
                    <h3>Emili Lucy</h3>
                    <span>Project Manager</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/team4.jpg" alt="image">

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/team5.jpg" alt="image">

                <div class="team-content">
                    <h3>Steve Eva</h3>
                    <span>Creative Designer</span>
                </div>
            </div>

            <div class="single-team">
                <ul class="team-social">
                    <li><a href="#"><i class="flaticon-logo"></i></a></li>
                    <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                    <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                </ul>

                <img src="assets/img/team1.jpg" alt="image">

                <div class="team-content">
                    <h3>Josh Buttler</h3>
                    <span>Content Writer</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->
