<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services Details</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="services-details-desc">
                        <h3>Email Marketing</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco <b>laboris nisi ut aliquip ex ea commodo consequat</b>. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>

                            <div class="item">
                                <img src="assets/img/blog-details.jpg" alt="img">
                            </div>
                        </div>
                        <h3>Why Choose This Service</h3>
                        <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Great Technology</li>
                            <li><i class="flaticon-tick"></i> Delivery On Time</li>
                            <li><i class="flaticon-tick"></i> Expert Engineers</li>
                            <li><i class="flaticon-tick"></i> Creative Architecture</li>
                            <li><i class="flaticon-tick"></i> Industrial Construction</li>
                            <li><i class="flaticon-tick"></i> 24/7 Support</li>
                        </ul>

                        <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>

                        <h3>Our Work Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/service-details" class="active">Email Marketing <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Market Analysis <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Keyword Research <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Digital Branding <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Creative Solutions <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Marketing Solutions <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Business Analytics <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">UX & UI Design <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Photography <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/service-details">Management <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Download</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="#">Our Brochure Pdf <i class="flaticon-edit"></i></a></li>
                            <li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                            <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
