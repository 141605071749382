<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Testimonials</h1>
        <ul>
            <li><a href="index-4.html">Home</a></li>
            <li>Testimonials</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Testimonials Area -->
<section class="testimonials-area uk-testimonials uk-section bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                </div>

                <div class="quotation-profile">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="profile-info">
                        <h3>David Warner</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                </div>

                <div class="quotation-profile">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="profile-info">
                        <h3>John Lucy</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                </div>

                <div class="quotation-profile">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="profile-info">
                        <h3>Trent Boult</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                </div>

                <div class="quotation-profile">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="profile-info">
                        <h3>Corey Anderson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                </div>

                <div class="quotation-profile">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="profile-info">
                        <h3>Marta Steven</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="testimonials-single-item">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                </div>

                <div class="quotation-profile">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="profile-info">
                        <h3>Kane Williamson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials Area -->
